<div class="empty-state" [class.big]="big" [class.set]="set">
  <div>
    <img [src]="icon" alt="empty page" />
  </div>
  <div>
    <h3 [class]="titleClass" *ngIf="isTitle">
      {{ title || emptyText.title }}
    </h3>
    <ng-container *ngTemplateOutlet="subtitleRef() || defaultSubView" />
  </div>
</div>

<ng-template #defaultSubView>
  <p [class]="subClass">
    {{ subtitle || emptyText.subtitle }}
  </p>
</ng-template>
