import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";

/*
* USAGE
* Template:
* * --------------------------------------------------------------
* <roam-icon [color]="'primary'" [name]="result.slug"></roam-icon>
* ----------------------------------------------------------------
* @color: color name -> config file styles/components/bg.scss
* @name: icon name -> config file assets/svg/roam
* */
@Component({
  selector: 'roam-icon',
  standalone: true,
  imports: [CommonModule, InlineSVGModule],
  template:`
    <span [class]="'svg-'+color+' svg-'+size"
          [class.d-flex]="!inline"
          [style.fill]="fillColor"
          [inlineSVG]="srcPath+name+'.svg'"
          (click)="onClick.emit($event)"
    ></span>
  `
})
export class RoamIconComponent implements OnInit{
  @Input()
  public name!: any;

  @Input()
  public color: string='';

  @Input()
  public fillColor: string = '';

  @Input()
  public size: string = ''

  @Input()
  public class!: string;

  @Input()
  public src: boolean = true;

  @Input()
  public inline: boolean = false;

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  @Input()
  public srcPath: string = 'assets/svg/roam/';

  ngOnInit(): void {
    this.setIconPath();
  }

  private setIconPath(): void {
    if(!this.src) {
      this.srcPath = 'assets/svg/';
    }
  }
}
